import { render, staticRenderFns } from "./WebHead.vue?vue&type=template&id=37bd4f1d&scoped=true&"
import script from "./WebHead.vue?vue&type=script&lang=js&"
export * from "./WebHead.vue?vue&type=script&lang=js&"
import style0 from "./WebHead.vue?vue&type=style&index=0&id=37bd4f1d&prod&lang=scss&"
import style1 from "./WebHead.vue?vue&type=style&index=1&id=37bd4f1d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_lodash@4.17.21_vue-template-compiler@2.7.10_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bd4f1d",
  null
  
)

export default component.exports